<template>
	<div>
		<van-nav-bar
			title="登錄"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="login">
			
			<div class="login_title">米因健康大數據</div>
			<div class="logo">
				<van-image class="logo_img" :src="require('@/assets/images/vaccines/vaccines_logo.png')"></van-image>
			</div>

			<div class="form">
				<div class="form_item">
					<van-image class="login_id" :src="require('@/assets/images/vaccines/login_id.png')"></van-image>
					<van-field type="number" 
							v-model="mobile"
						   class="login_id_input" 
						   placeholder="請輸入手機號"  />
				</div>
				<div class="form_item send_cell">
					<van-image class="login_id" :src="require('@/assets/images/vaccines/login_send.png')"></van-image>
					<van-field type="number" 
							v-model="send"
						   class="login_id_input" 
						   placeholder="請輸入驗證碼" />
					<div class="action">
						<div class="action_btn" @click="handleCaptchaP" v-if="!isSend">獲取驗證碼</div>
						<div class="action_btn" v-else >{{last_time}}s後重新獲取</div>
					</div>
				</div>
				<div class="form_item send_cell">
					<van-image class="login_id" :src="require('@/assets/images/vaccines/invite_icon.png')"></van-image>
					<van-field type="text"
							v-model="invite_code"
						   class="login_id_input" 
						   placeholder="請輸入邀請碼(選填)"  />
				</div>
				<div class="tips">如果您有邀請碼,請填寫邀請碼</div>
			</div>

			<div class="foot_cell">
				<van-button class="sub_btn" @click="handleLogin">登錄</van-button>
			</div>

			<div class="agree">登錄即表明您同意<span @click="handleAgree">《用戶協議》</span></div>

			<div class="popup_suc" v-if="captchaShow">
				<div class="captcha_popup_wrap">
					<div class="captcha_box">
						<van-field type="text" class="captcha_input" placeholder="請輸入圖形碼" @input="handleCaptcha" />
						<van-image class="captcha_img" :src="captchaImg"></van-image>
						<div class="captcha_upadte" @click="handleCaptchaP" >換一張</div>
					</div>
					<van-button class="captcha_popup_btn" @click="handleCode">確定</van-button>
					<div class="popup_close" @click="handleClose">
						<van-image class="close_icon" :src="require('@/assets/images/vaccines/close_icon.png')"></van-image>
					</div>
				</div>
			</div>
			
			
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog, Field } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Field)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesLogin',
		data (){
			return {
				isSend: false,
				captchaShow: false,
				last_time: '',
				mobile: '',
				send: '',
				invite_code: '',
				captcha: '',
				captchaImg: ''
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleCaptchaP() {
				
			},
			handleCode() {
				
			},
			handleClose() {
				
			},
			handleCaptchaP() {
				
			},
			handleLogin(){
				
			},
			handleAgree() {
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.van-cell:not(:last-child)::after {
		border-bottom: none;
	}
	.login{
		width: 100%;
		min-height: 100vh;
		padding: 1.92rem 0.80rem;
		background-color: #fff;
	}
	.login_title {
	    width: 100%;
	    font-size: 0.52rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #32363E;
	    line-height: 0.61rem;
	}
	.logo {
	    width: 100%;
	    height: 1.76rem;
	    text-align: center;
	    margin-top: 0.68rem;
	}
	.logo_img {
	    width: 2.69rem;
	    height: 1.76rem;
	}
	
	.form {
	    width: 100%;
	    margin-top: 0.60rem;
	}
	
	.form_item {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    border-bottom: 0.01rem solid #F1F2F2;
	    padding: 0.28rem 0;
	}
	.login_id {
	    width: 0.36rem;
	    height: 0.36rem;
	}
	
	.login_id_input {
	    flex: 1;
	    padding-left: 0.36rem;
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	}
	.input_pla {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #C4C9D3;
	    line-height: 0.40rem;
	}
	.send_cell {
	    margin-top: 0.32rem;
	}
	
	.sub_btn {
	    width: 100% !important;
	    height: 0.92rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFFFF;
	    background-color: #6681FA;
	    border-radius: 0.16rem;
	    margin-top: 0.86rem;
	}
	
	.action_btn {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #6E87FC;
	    line-height: 0.40rem;
	}
	
	.agree {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.40rem;
	    margin-top: 0.32rem;
	    text-align: center;
	}
	.agree span {
	    color: #748DFB;
	}
	
	.tips {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #8C919A;
	    line-height: 0.40rem;
	    margin-top: 0.08rem;
	}
	
	.popup_suc {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    z-index: 99;
	}
	.popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.74rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	
	.captcha_box {
	    width: 100%;
	    display: flex;
	    align-items: flex-end;
	    /* text-align: center; */
	    padding-top: 0.50rem;
	}
	
	.captcha_img {
	    width: 1.40rem;
	    height: 0.74rem;
	    margin-left: 0.30rem;
	    margin-right: 0.10rem;
	    border-radius: 0.08rem;
	}
	
	.captcha_input {
	    width: 2.60rem;
	    border: 0.01rem solid #D7DBE1;
	    height: 0.74rem;
	    border-radius: 0.08rem;
	    padding: 0 0.16rem;
	}
	
	.captcha_popup_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.30rem;
	}
	
	.popup_close {
	    position: absolute;
	    bottom: -1.36rem;
	    left: 44%;
	    width: 0.72rem;
	    height: 0.72rem;
	}
	.close_icon {
	    width: 0.72rem;
	    height: 0.72rem;
	}
	
	.captcha_popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.40rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	
	.captcha_upadte {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #6681FA;
	}
	
</style>
